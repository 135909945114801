/* global Firebase */
(function (angular) {
  'use strict';

  angular.module('app.firebase')
    .factory('FirebaseService', ['$firebaseAuth', function ($firebaseAuth) {
      var firebaseConfig = {
          apiKey: "AIzaSyCwISdlD-9Spcmcl-AeZnpNW4NxZnzBJxk",
          authDomain: "dev-lightbulb75.firebaseapp.com",
          databaseURL: "https://dev-lightbulb75.firebaseio.com",
          projectId: "dev-lightbulb75",
          storageBucket: "dev-lightbulb75.appspot.com",
          messagingSenderId: "772928978536",
          appId: "1:772928978536:web:9af311bd37066faaf7c5dd"
      };
      firebase.initializeApp(firebaseConfig);

      var service = {
        dbRef: undefined,

        getDbRef: function () {
          if (this.ref === undefined) {
            // Firebase.goOnline();
            this.dbRef = firebase.database().ref();
          }

          return this.dbRef;
        },

        unsetRef: function () {
          // Firebase.goOffline();
          this.dbRef = undefined;
        },

        onAuth: function (callback) {
          // this.getRef().onAuth(callback);
          $firebaseAuth().$onAuthStateChanged(callback);
        },

        offAuth: function (callback) {
          // this.getRef().offAuth(callback);
        }
      };

      return service;

    }]);

})(angular);